// extracted by mini-css-extract-plugin
export var active = "project-module--active--gR9d9";
export var buttonNext = "project-module--buttonNext--JFM8E";
export var buttonPrevious = "project-module--buttonPrevious--3P3v0";
export var gallery = "project-module--gallery--AEeVk";
export var galleryWrapper = "project-module--galleryWrapper--leQQQ";
export var galleryWrapperMobile = "project-module--galleryWrapperMobile--F1HcX";
export var image = "project-module--image--Uqest";
export var imageWrapper = "project-module--imageWrapper--nQGhA";
export var layoutWrapper = "project-module--layoutWrapper--B4O9W";
export var mainContainer = "project-module--mainContainer--ma06P";
export var mainContent = "project-module--mainContent--X5mNW";